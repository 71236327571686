<!-- eslint-disable -->
<template>
  <div class="page_box">
    <sb-headline title="保险机构"></sb-headline>
    <my-table ref="list" :data="listData" @page="pageHandler" :listLoading="listLoading">
      <template #header="params">
        <div class="search_box">
          <el-row :gutter="10">
            <el-col :span="4">
              <div class="search_item">
                <div>保司名称：</div>
                <el-input v-model="searchData.orgName"></el-input>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-top: 10px;">
            <el-col :span="4" :offset="20" style="text-align: end;">
              <el-button @click="pageHandler(params, 1, 'reset')">重置</el-button>
              <el-button @click="pageHandler(params, 1)" type="primary">查询</el-button>
            </el-col>
          </el-row>
        </div>
      </template>
      <template #navBar>
        <div class="table_nav_bar">
          <el-button type="primary" @click="insuranceInstituteAdd">新增保司</el-button>
        </div>
      </template>
      <el-table-column label="保司编号" fixed="left" prop="orgCode" :min-width="140"></el-table-column>
      <el-table-column label="保司名称" prop="orgName" :min-width="160"></el-table-column>
      <el-table-column label="保司Logo" prop="logoLink" :min-width="160">
        <template slot-scope="scope">
          <img class="insurance_logo_link" :src="scope.row.logoLink" alt="加载失败">
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="crowdName" :min-width="160">
        <template slot-scope="scope">
          <el-switch
            @change="(e)=>changeState(e,scope.row, 1)"
            v-model="scope.row.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="true"
            :inactive-value="false">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="保司描述" prop="description" :min-width="300"></el-table-column>
      <el-table-column label="更新时间" prop="mtime" :min-width="180"></el-table-column>
      <el-table-column label="操作" fixed="right" prop="" width="120">
        <template slot-scope="scope">
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(1, scope.row)">编辑返点</el-button>
        </template>
      </el-table-column>
    </my-table>

    <!-- 新增保司弹窗 -->
    <el-dialog title="保司操作" class="insurance_dialog" :visible.sync="insuranceAddVisible" width="80%">
      <el-form :model="insuranceAddForm" ref="insuranceAddForm" :disabled="insuranceAddForm.orgCode?true:false" :rules="insuranceAddRules" label-width="100px">
        <div class="insurance_dialog_module">
          <div class="dialog_module_title">
            <div class="title_line"></div>
            <div>保司信息</div>
          </div>
          <el-form-item label="保司名称：" prop="orgName">
            <el-input v-model="insuranceAddForm.orgName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="保司Logo：" prop="logoLink">
            <file-upload
              v-if="insuranceAddVisible"
              v-model="insuranceAddForm.logoLink"
              :uploadBusinessInfo="{
                businessType: '0'
              }"
              :defaultFileList="logoLinkList"
              :maxNum="1"
              width="72px"
              height="72px"
              @uploadSuccess="uploadSuccess('purchaseImage')"
            ></file-upload>
          </el-form-item>
          <el-form-item label="启用状态：">
            <el-switch
              v-model="insuranceAddForm.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false">
            </el-switch>
          </el-form-item>
          <el-form-item label="保司描述：" prop="description">
            <el-input v-model="insuranceAddForm.description" type="textarea" autocomplete="off"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <!-- 返点数据列表 -->
      <el-form :model="rebateData" ref="rebateList" :rules="insuranceAddRules">
        <div class="insurance_dialog_module" v-if="true">
          <div class="dialog_module_title">
            <div class="title_line"></div>
            <div>返点信息</div>
            <el-button type="text" size="mini" @click="rebateAddFn">添加返点信息-增行</el-button>
          </div>
          <el-table
            v-loading="rebateLoading"
            class="popup_table"
            :data="rebateData.rebateList"
            border
            size="mini"
            style="width: 100%">
            <el-table-column prop="date" label="城市" min-width="140">
              <template slot-scope="scope">
                <el-form-item :prop="`rebateList.${scope.$index}.price`" :rules="insuranceAddRules.price">
                  <el-select v-model="scope.row.cityCode" @change="(e)=>citySelectFn(e,scope.$index)" filterable clearable placeholder="请选择">
                    <el-option-group
                      v-for="group in cityList"
                      :key="group.initials"
                      :label="group.initials">
                      <el-option
                        v-for="(item,index) in group.cityList"
                        :key="index"
                        :label="item.areaName"
                        :value="item.areaCode">
                      </el-option>
                    </el-option-group>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="能源类型" min-width="140">

              <template slot-scope="scope">
                <el-form-item>
                  <el-select v-model="scope.row.energyType">
                    <el-option label="燃油" :value="1"></el-option>
                    <el-option label="新能源" :value="2"></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="新保-交强险返利(%)" min-width="140">
              <template slot-scope="scope">
                <el-form-item :prop="`rebateList.${scope.$index}.newCtaliRebate`" :rules="insuranceAddRules.newCtaliRebate">
                  <el-input v-model="scope.row.newCtaliRebate"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="新保-商业险返利(%)" min-width="140">
              <template slot-scope="scope">
                <el-form-item :prop="`rebateList.${scope.$index}.newCommercialRebate`" :rules="insuranceAddRules.newCommercialRebate">
                  <el-input v-model="scope.row.newCommercialRebate"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="续保-交强险返利(%)" min-width="140">
              <template slot-scope="scope">
                <el-form-item :prop="`rebateList.${scope.$index}.renewalCtaliRebate`" :rules="insuranceAddRules.renewalCtaliRebate">
                  <el-input v-model="scope.row.renewalCtaliRebate"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="续保-商业险返利(%)" min-width="140">
              <template slot-scope="scope">
                <el-form-item :prop="`rebateList.${scope.$index}.renewalCommercialRebate`" :rules="insuranceAddRules.renewalCommercialRebate">
                  <el-input v-model="scope.row.renewalCommercialRebate"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="提前报价期限(天)" min-width="120">
              <template slot-scope="scope">
                <el-form-item :prop="`rebateList.${scope.$index}.quoteDeadline`" :rules="insuranceAddRules.quoteDeadline">
                  <el-input v-model="scope.row.quoteDeadline"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="操作" min-width="80" align="center" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" @click="rebateDelFn(scope.$index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            style="margin-top: 10px;text-align: center;"
            @size-change="rebateSizeChange"
            @current-change="rebateCurrentChange"
            :current-page="pageData.num"
            :page-sizes="[5, 10, 20, 30]"
            :page-size="pageData.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageData.total">
          </el-pagination>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="insuranceAddVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import FileUpload from "@/components/scope/file-upload.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { FileUpload },
    data() {
    //这里存放数据
      return {
        listData: [{},{},{}],
        // 搜索数据
        searchData: {
          orgName: undefined,                  //保司名称
        },
        listLoading: false,             //列表loading

        insuranceAddVisible: false,     //新增保司弹窗状态

        insuranceAddForm: {             //新增保司表单数据
          orgCode: '',                  //保司编码
          orgName: '',                  //保司名称
          logoLink: "",                 //保司Logo
          status: true,                 //保司启用状态
          description: ""               //保司描述
        },
        insuranceAddRules: {            //表单验证
          orgName: [{ required: true, message: '请输入保司名称', trigger: 'blur' }],                  //保司名称

          // cityCode: '',                 //城市code
          // provinceCode: '',             //省份code
          // quoteDeadline: [{ required: true, message: '请输入报价期限', trigger: 'change' }],            //报价期限
          // newCtaliRebate: '',           //新保交强险返利（百分比）
          // newCommercialRebate: '',      //新保商业险返利（百分比）
          // renewalCtaliRebate: '',       //续保交强险返利（百分比）
          // renewalCommercialRebate: '',  //续保商业险返利（百分比）
        },
        logoLinkList: [],               //保司logoList
        rebateData: {
          rebateList: [                   //返利配置数据
            
          ],
        },
        rebateLoading: false,             //返利数据loading
        
        cityList: [],                       //城市列表
        pageData: {                                 //分页数据
          num: 1,
          size: 5,
          total: 0
        },
      };
    },
  filters: {
    stateFilter: function(val) {
      
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    pageHandler(params, num, type) {
      if(type == 'reset'){
        this.resetSearchData();
      }
      this.getActivityList(params, num ? num : params.pageNum, params.pageSize);
    },
    // 新增保司
    insuranceInstituteAdd(){
      this.insuranceAddVisible = true;
      this.insuranceAddForm = {             //新增保司表单数据
        orgCode: '',                  //保司编码
        orgName: '',                  //保司名称
        logoLink: "",                 //保司Logo
        status: true,                 //保司启用状态
        description: ""               //保司描述
      }
      this.rebateData.rebateList = [];
    },
    // 弹窗确认
    dialogConfirm(){
      if(this.insuranceAddForm.orgCode){
        this.rebatesAdd()// 返利保存
      }else{
        this.$refs.insuranceAddForm.validate((valid) => {
          if (valid) {
            this.$store.dispatch('insurance/postInsuranceInstituteAdd',{
              ...this.insuranceAddForm
            }).then(res => {
              // 返利保存
              this.insuranceAddForm.orgCode = res.data.orgCode;
              if(this.rebateData.rebateList.length>0){
                this.rebatesAdd()// 返利保存
              } else {
                this.$message.success('操作成功');
                this.$refs.list.init();
                this.insuranceAddVisible = false;
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    // 返利保存
    rebatesAdd(){
      this.$store.dispatch('insurance/postInsuranceInstituteRebatesAdd',{
        rebates: this.rebateData.rebateList,
        orgCode: this.insuranceAddForm.orgCode
      }).then(res => {
        this.$message.success('操作成功');
        this.$refs.list.init();
        this.insuranceAddVisible = false;
      })
    },
    // 图片上传成功
    uploadSuccess(e){
      // this.$refs.form.clearValidate(e)
    },

    // 添加返利数据
    rebateAddFn(){
      this.rebateData.rebateList.push({
        cityCode: '',                 //城市code
        provinceCode: '',             //省份code
        quoteDeadline: '',            //报价期限
        newCtaliRebate: '',           //新保交强险返利（百分比）
        newCommercialRebate: '',      //新保商业险返利（百分比）
        renewalCtaliRebate: '',       //续保交强险返利（百分比）
        renewalCommercialRebate: '',  //续保商业险返利（百分比）
      })
    },
    // 返利列表删除
    rebateDelFn(index){
      this.$confirm('是删除此条返利配置?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.rebateData.rebateList.splice(index,1);
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消操作'
        });
      })
    },

    // 城市选择
    citySelectFn(e,index){
      console.log(e,index)
      let rebateData = JSON.parse(JSON.stringify(this.rebateData));
      this.cityList.find(item => {
        item.cityList.find(ele => {
          if(e == ele.areaCode){
            rebateData.rebateList[index].provinceCode = ele.parentAreaCode;
          }
        })
      })
      this.rebateData = rebateData;
    },

    rebateSizeChange(e){
      this.pageData.size = e;
      this.getRebatesList();
    },
    rebateCurrentChange(e){
      this.pageData.num = e;
      this.getRebatesList();
    },

    // 表格内操作
    tableHandle(type, row) {
      switch (type) {
        case 1:     //编辑返点
          this.insuranceAddVisible = true;
          Object.keys(this.insuranceAddForm).forEach(key => {
            this.insuranceAddForm[key] = row[key]
          })
          this.logoLinkList = JSON.parse(JSON.stringify([{url: row.logoLink}]))
          // 获取当前返利数据列表
          this.getRebatesList();
          break;

        case 3:     //返点更新记录

          break
        default:
          break;
      }
    },

    // 重置搜索数据
    resetSearchData(e) {
      this.searchData = {
        orgName: undefined
      }
    },

    // 获取保险机构列表
    getActivityList(params,pageNum,pageSize) {
      this.listLoading = true;
      this.$store.dispatch('insurance/postInsuranceInstituteList',{
        ...this.searchData,
        pageNum,
        pageSize,
      }).then(res => {
        if(res.code == 0){
          console.log(res)
          this.listData = res.data.list;
          params.render(res.data.total);
        }
        this.listLoading = false;
      })
    },

    // 获取城市列表
    getCityData(){
      this.$store.dispatch('management/crowdManagementCityList',{}).then(res => {
        this.cityList = res.data;
      })
    },

    // 获取当前保司返利列表
    getRebatesList(){
      this.rebateLoading = true;
      this.$store.dispatch('insurance/postInsuranceInstituteRebatesList',{
        orgCode: this.insuranceAddForm.orgCode,
        pageNum: this.pageData.num,
        pageSize: this.pageData.size
      }).then(res => {
        console.log(res.data)
        this.rebateData.rebateList = res.data.list;
        this.pageData.total = res.data.total;
        this.rebateLoading = false;
      })
    }

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (this.$route.query.activityId) {
      this.searchData.activityId = this.$route.query.activityId;
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // 获取城市列表
    this.getCityData();
  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box {
    padding: 0 20px;
    .search_box {
      background: #fff;
      padding: 20px;
      margin-top: 20px;
    }
    .table_nav_bar{
      width: 100%;
      background: #fff;
      padding: 20px 20px 0 20px;
      box-sizing: border-box;
      .el-button{
        margin-right: 10px;
      }
    }
    .table_row_rule{
      word-break: break-all; /** 换行，长单词会分成两行显示 **/
      text-overflow: ellipsis;/** 隐藏文本省略号 ... **/
      display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
      -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
      -webkit-line-clamp: 1; /** 显示的行数 **/
      overflow: hidden;  /** 隐藏超出的内容 **/;
    }
  }
  .handle_btn{
    padding: 0;
  }
  .channel_item{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    >.el-input:nth-child(1){
      width: 260px;
    }
  }
  .channel_item:last-child{
    margin: 0;
  }
  .popularize_list_qrcode{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .qrcode_img_src{
    position: absolute;
    top: -10000%;
    z-index: -10000;
  }
  .search_item{
    display: flex;
    align-items: center;
    >div:first-child{
      flex-shrink: 0;
    }
  }
  .insurance_logo_link{
    width: 72px;
    height: 72px;
    display: block;
  }
  .insurance_dialog{
    ::v-deep .el-dialog__body{
      padding: 10px 20px !important;
    }
    .el-input{
      width: 50%;
    }
    .insurance_dialog_module{
      .dialog_module_title{
        font-weight: 500;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .title_line{
          width: 4px;
          height: 16px;
          background: #409EFF;
          margin-right: 5px;
          border-radius: 1px;
        }
      }
      .popup_table{
        .el-input{
          width: 100%;
        }
      }
    }
  }
  ::v-deep .el-table__header thead tr th:first-of-type, .el-table__body tbody tr td:first-of-type{
    padding: 0 !important;
  }
  ::v-deep .el-table__body tbody tr td:first-of-type {
    padding-left: 0px !important;
  }
</style>
